<template>
  <v-row>
    <v-col lg="10" offset-md="1">
      <s-crud
        formPermanent
        sortable
        title="Numeraciones"
        pdf
        excel
        edit
        add
        remove
        :config="this.config"
        @save="saveNum($event)"
      >
        <template scope="props">
          <v-container v-if="props.item != null" class="pb-0">
            <v-row class="justify-center">
              <v-col cols="10" md="6" class="s-col-form">
                <v-label ><b>Tabla</b></v-label>
                <v-autocomplete
                  v-if="props.item.NumID <= 0"
                  v-model="props.item.NumTable"
                  item-value="GenDescription"
                  item-text="GenDescription"
                  :items="tablesnonumeration"
                  dense
                  outlined
                  hide-details
                  clearable
                />
                <s-text
                  disabled
                  v-else
                  v-model="props.item.NumTable"
                />
              </v-col>
              <v-col cols="2" class="s-col-form">
                <s-text
                  v-model="props.item.NumCorrelative"
                  label="Correlativo"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </s-crud>
    </v-col>
  </v-row>
</template>

<script>
import _sNumeration from "@/services/General/NumerationService";

export default {
  data: () => ({
    tablesnonumeration: [],
    config: {
      model: {
        NumID: "ID",
        NumTable: "string",
        NumCorrelative: "string",
        SecStatus: "status",
      },
      service: _sNumeration,

      headers: [
        {
          text: "ID",
          sortable: false,
          value: "NumID",
          align: "end",
          width: "15%",
        },
        {
          text: "Tabla",
          value: "NumTable",
          sortable: true,
        },
        {
          text: "Correlativo",
          value: "NumCorrelative",
          align: "end",
          width: "20%",
        },

        {
          text: "Estado",
          value: "SecStatus",
          sortable: true,
          align: "center",
          width: "15%",
        },
      ],
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      _sNumeration.tablesnonumeration(this.$fun.getUserID()).then((r) => {
        this.tablesnonumeration = r.data;
      });
    },
    validatedNum(item) {
      let isValid = false;
      let message = "";

      isValid = item.NumTable.length > 0;

      if (!isValid) message = "Ingrese nombre de tabla";
      else {
        isValid = item.NumCorrelative >= 0;
        if (!isValid) message = "No se permite correlativo negativo";
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    saveNum(item) {
      if (this.validatedNum(item)) {
        item.save();
        this.initialize();
      }
    },
  },
};
</script>
