import Service from "../Service";

const resource = "numeration/";

export default {
  save(num, requestID) {
    return Service.post(resource + "save", num, {
      params: { requestID: 42 },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
  tablesnonumeration(requestID) {
    return Service.post(
      resource + "tablesnonumeration",
      {},
      {
        params: { requestID: requestID },
      }
    );
  },
};
